import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomListsItem } from "./api/CustomListsModel";
import { getCustomListsAPI, updateCustomListAPI } from "./api/CustomListsApi";

//state
export interface CustomListsState {
  customLists: CustomListsItem[];
  currentListId: string;
  currentListValues: string[];
  loadingStatus: "idle" | "pending" | "resolved" | "rejected";
  error: string | null;
}

const initialState: CustomListsState = {
  customLists: [],
  currentListId: "",
  loadingStatus: "idle",
  error: null,
  currentListValues: [],
};

//Thunks
export const getCustomLists = createAsyncThunk(
  "customLists/getCustomLists",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCustomListsAPI();
      if (response.success === true) return response.data;
      else return rejectWithValue(response.error);
    } catch (e: any) {
      return rejectWithValue("Error inesperado");
    }
  }
);

export const updateCustomList = createAsyncThunk(
  "customLists/updateCustomList",
  async (req: { listId: string; values: string[] }, { rejectWithValue }) => {
    try {
      const response = await updateCustomListAPI(req);
      if (response.success === true) return response.data;
      else return rejectWithValue(response.error);
    } catch (e: any) {
      return rejectWithValue("Error inesperado");
    }
  }
);

//Slice
export const customListsSlice = createSlice({
  name: "customLists",
  initialState,
  //REDUCERS
  reducers: {
    selectList: (state, action: PayloadAction<string>) => {
      state.currentListId = action.payload;
    },
    addValues: (state, action: PayloadAction<string[]>) => {
      state.currentListValues = state.currentListValues.concat(action.payload);
    },
  },
  extraReducers: (builder) => {
    //Get Custom Lists
    builder.addCase(getCustomLists.pending, (state) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(getCustomLists.fulfilled, (state, action) => {
      state.loadingStatus = "resolved";
      state.customLists = action.payload!!;
    });
    builder.addCase(getCustomLists.rejected, (state, action) => {
      state.loadingStatus = "rejected";
      state.error = action.payload as string;
    });
    //Update Custom List
    builder.addCase(updateCustomList.pending, (state) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(updateCustomList.fulfilled, (state) => {
      state.loadingStatus = "resolved";
      state.currentListId = "";
      state.currentListValues = [];
    });
    builder.addCase(updateCustomList.rejected, (state, action) => {
      state.loadingStatus = "rejected";
      state.error = action.payload as string;
    });
  },
});

//Exports
export const { selectList, addValues } = customListsSlice.actions;

export default customListsSlice.reducer;

export const selectCustomLists = (state: { customLists: CustomListsState }) =>
  state.customLists.customLists;
export const selectCurrentListId = (state: { customLists: CustomListsState }) =>
  state.customLists.currentListId;
export const selectCurrentListValues = (state: {
  customLists: CustomListsState;
}) => state.customLists.currentListValues;
export const selectLoadingStatusCustomLists = (state: {
  customLists: CustomListsState;
}) => state.customLists.loadingStatus;
export const selectErrorCustomLists = (state: {
  customLists: CustomListsState;
}) => state.customLists.error;
