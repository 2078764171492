import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";
import {
    addValues,
    getCustomLists,
    selectCurrentListId,
    selectCurrentListValues,
    selectCustomLists,
    selectErrorCustomLists,
    selectList,
    selectLoadingStatusCustomLists,
    updateCustomList
} from "./customListsSlice";
import { CustomListsItem } from "./api/CustomListsModel";
import Loader from "react-spinners/ClipLoader";
export const CustomListsEditor = () => {
    const dispatch = useAppDispatch();
    //State selectors
    const lists = useAppSelector(selectCustomLists);
    const currentListId = useAppSelector(selectCurrentListId);
    const currentListValues = useAppSelector(selectCurrentListValues);
    const loadingStatus = useAppSelector(selectLoadingStatusCustomLists);
    const error = useAppSelector(selectErrorCustomLists);

    //UseEffects
    useEffect(() => {
        //Get custom lists
        dispatch(getCustomLists());
    }, [])

    //Component functions
    function getListCard(list: CustomListsItem) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                height: "100px",
                width: "90%",
                maxHeight: "100%",
                backgroundColor: currentListId === list.id ? "rgba(0,0,0,0.6)" : "rgba(0,0,0,0.2)",
                padding: "20px",
                margin: "20px",
                borderRadius: "10px",
                cursor: "pointer"

            }}
                onClick={() => {
                    dispatch(selectList(list.id));
                }}
            >
                <div className="text-normal primary-color">
                    {list.name}
                </div>
                <div className="text-normal primary-color" style={{ fontSize: "12px" }}>
                    {list.description}
                </div>
                <div className="text-normal primary-color" style={{ fontSize: "12px" }}>
                    {list.id}
                </div>
                <div className="text-normal primary-color" style={{ fontSize: "12px" }}>
                    {/* date */}
                    Última actualización: {new Date(list.lastUpdate ?? "").toLocaleDateString()}
                </div>
            </div>
        )
    }

    function uploader() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                height: "20%",
                width: "90%",
                maxHeight: "100%",
                padding: "20px",
                margin: "20px",
                borderRadius: "10px",
            }}>
                <input id="uploader" style={{ display: "none" }} type="file"
                    onSubmit={(e) => {
                        e.preventDefault();
                        console.log("submit");
                    }}
                    onChange={(e) => {
                        console.log(e.target.files);
                        let file = e.target.files?.item(0);
                        if (!file) return;
                        //Check if file is a .csv
                        if (!file.name.toLocaleLowerCase().endsWith(".csv")) {
                            alert("El archivo debe ser un .csv");
                        };
                        const reader = new FileReader();
                        reader.onload = function (event) {
                            if (event.target) {
                                const fileContent = event.target.result;
                                //Parse CSV
                                parseCSV(fileContent as string);
                            }
                        };
                        reader.onerror = function (event) {
                            console.error("File could not be read! Code " + event.target?.error?.cause);
                            alert("Error al leer el archivo");
                        }
                        reader.readAsText(file);
                    }}
                />
                <button className="boton1" style={{
                }} onClick={(
                    () => {
                        let uploader = document.getElementById("uploader") as HTMLInputElement;
                        uploader.click();
                    })}
                >
                    Selecciona un archivo .CSV
                </button>
            </div>
        )
    }

    //Helper functions
    function parseCSV(csv: string) {
        let lines = csv.split("\n");
        let result: string[] = [];
        let headers = lines[0].split(",");
        if (headers.length > 1) {
            alert("El CSV solo debe tener 1 columna")
            return;
        }
        for (let i = 1; i < lines.length; i++) {
            if (lines[i].trim() === "") continue;
            result.push(lines[i]);
        }
        console.log("Parsed CSV:");
        console.log(result);
        dispatch(addValues(result));
    }

    if (loadingStatus === "pending") {
        return (
            <div
                className="text-normal text-medium primary-color"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    padding: "50px",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Loader color="white" loading={true} size={150} />
                Cargando...
            </div>
        );
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            height: "100%",
            width: "90%",
            maxHeight: "100%",
            backgroundColor: "rgba(0,0,0,0.2)",
            padding: "20px",
            margin: "20px",
            borderRadius: "10px",
        }}>
            <div className="text-normal primary-color" style={{
                fontSize: "25px"
            }}>
                Editor de listas personalizadas
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "start",
                    height: "100%",
                    width: "90%",
                    maxHeight: "100%",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    padding: "20px",
                    margin: "20px",
                    borderRadius: "10px",
                }}>
                {/* Selector */}
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    height: "100%",
                    width: "30%",
                    maxHeight: "100%",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    padding: "20px",
                    margin: "20px",
                    borderRadius: "10px",
                }}>
                    <div className="text-normal primary-color">Selecciona una lista</div>
                    <div>{lists.map((list) => {
                        return (<div key={list.id}>{getListCard(list)}</div>)
                    })}
                    </div>

                </div>
                {/* Uploader */}
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    height: "100%",
                    width: "90%",
                    maxHeight: "100%",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    padding: "20px",
                    margin: "20px",
                    borderRadius: "10px",
                }}>
                    {(currentListId === "") && (<div className="text-normal primary-color">Selecciona una lista</div>)}

                    {(currentListId !== "") && (
                        uploader()
                    )}
                    {(currentListValues.length > 0) && (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            height: "100%",
                            width: "90%",
                            maxHeight: "100%",
                            padding: "20px",
                            margin: "20px",
                            borderRadius: "10px",
                        }} >

                            <div className="text-normal primary-color" style={{ marginLeft: "10px" }}>
                                Se leyeron {currentListValues.length} valores
                            </div>
                            <div className="text-normal primary-color" style={{ marginLeft: "10px" }}>
                                Primer valor: {currentListValues[0]}
                            </div>
                            <div className="text-normal primary-color" style={{ marginLeft: "10px" }}>
                                Ultimo valor: {currentListValues[currentListValues.length - 1]}
                            </div>
                            <button className="boton1" style={{
                                marginTop: "20px"
                            }} onClick={(
                                () => {
                                    //Update list
                                    dispatch(updateCustomList({ listId: currentListId, values: currentListValues }));
                                })}
                            >
                                Subir archivo cargado y aplicar cambios
                            </button>
                        </div>


                    )}

                </div>
            </div>
        </div>
    )
}