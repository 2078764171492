import axiosApiInstance from "../../../../../helpers/interceptor";
import {
  CustomListsItem,
  CustomListsResponseWrapper,
  UpdateCustomListsRequest,
} from "./CustomListsModel";

const getCustomListsPath = "hyperflow/customLists/getAll";
const updateCustomListByIdPath = "hyperflow/customLists/update";

export const getCustomListsAPI = async (): Promise<
  CustomListsResponseWrapper<CustomListsItem[]>
> => {
  try {
    let lists = await axiosApiInstance.get(getCustomListsPath);
    if (lists.status !== 200) {
      return { success: false, data: [], error: `error: ${lists.status}` };
    }
    return { success: true, data: lists.data, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: "Error inesperado" };
  }
};

export const updateCustomListAPI = async (
  req: UpdateCustomListsRequest
): Promise<CustomListsResponseWrapper<string>> => {
  try {
    let lists = await axiosApiInstance.post(updateCustomListByIdPath, req);
    if (lists.status !== 200) {
      return { success: false, data: "", error: `error: ${lists.status}` };
    }
    return { success: true, data: "", error: "" };
  } catch (e: any) {
    return { success: false, data: "", error: "Error inesperado" };
  }
};
