import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { TopPanel } from "../components/TopPanel";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { changeCurrentProduct } from "../../common/commonSlice";
import { Outlet, useNavigate } from "react-router-dom";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { ReactComponent as DownloadIcon2 } from "../../../Assets/common/images/downloadIcon2.svg";
import { Category } from "../components/sidePanel/categories/Category";
import {
  selectActiveDashboardComponent,
  selectLoadingReport,
  changeActiveComponent,
  selectCurrentFilteredDashboard,
  changeCurrentFilters,
  downloadReport,
  getAsesores,
  selectDataAsesores,
  selectDataFlows,
  getFilterOptions,
  setNameAdvisor,
} from "./DashboardSlice";
import { selectClientId, selectRoles } from "../../common/commonSlice";
import "./DashboardStyles.scss";
import Alert from "react-bootstrap/Alert";
import { DropDown } from "../../common/components/Btns/DropDown";

export const DashboardClient = () => {
  const activeComponent = useAppSelector(selectActiveDashboardComponent);
  const currentFilteredRequest = useAppSelector(selectCurrentFilteredDashboard);
  const loadingDownloadReport = useAppSelector(selectLoadingReport);
  const roles = useAppSelector(selectRoles);
  const idClient = useAppSelector(selectClientId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [reportGenerated, setReportGenerated] = useState(false);
  const [showWaitingMessage, setShowWaitingMessage] = useState(0);
  const [canGenerateReport, setCanGenerateReport] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [advisorSelect, setAdvisorSelect] = useState<{
    value: string;
    label: string;
  }>();
  const [flowSelect, setFlowSelect] = useState<{
    value: string;
    label: string;
  }>();

  const [statusSelect, setStatusSelect] = useState<{
    value: string;
    label: string;
  }>();

  const asesores = useAppSelector(selectDataAsesores);
  const listFlowsOptions = useAppSelector(selectDataFlows);

  const loadingMessage = [
    "Generando Reporte...",
    "Espere un momento por favor",
    "Cargando datos",
    "Por favor, espere",
  ];

  useEffect(() => {
    if (!asesores) {
      dispatch(getAsesores());
    }
    if (!listFlowsOptions) {
      dispatch(getFilterOptions());
    }
  }, [asesores, listFlowsOptions, dispatch]);
  const convertDays = (startDate: string | null, endDate: string | null) => {
    if (startDate && endDate !== null) {
      let startDateMs = new Date(startDate);
      let endDateMs = new Date(endDate);
      let diffDays = Math.abs(endDateMs.getTime() - startDateMs.getTime());
      let days = Math.ceil(diffDays / (1000 * 3600 * 24));

      if (days >= 7 && days < 14) {
        return `Tiempo promedio de generación del reporte: 15 segundos.`;
      } else if (days >= 14 && days < 60) {
        return `Tiempo promedio de generación del reporte: 30 segundos.`;
      } else if (days >= 60) {
        return `Tiempo promedio de generación del reporte: 1 minuto.`;
      } else {
        return `Tiempo promedio de generación del reporte: 10 segundos.`;
      }
    }
    return "";
  };

  let totalDays = convertDays(
    currentFilteredRequest.endDate,
    currentFilteredRequest.startDate
  );

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "Reports") {
      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={false}
            calendarType="double"
            open={true}
            endDate={
              currentFilteredRequest.endDate
                ? new Date(currentFilteredRequest.endDate)
                : null
            }
            startDate={
              currentFilteredRequest.startDate
                ? new Date(currentFilteredRequest.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
                setCanGenerateReport(true);
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: null,
                    startDate: null,
                  })
                );
                setCanGenerateReport(false);
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: null,
                    startDate: null,
                  })
                );
              }
            }}
            items={[]}
          />
          {roles.includes("dashboard.admin") ? (
            <>
              {!canGenerateReport ? (
                <p className="text-medium primary-color message">
                  Seleccione el rango de fecha para generar el reporte
                </p>
              ) : (
                ""
              )}

              {canGenerateReport ? (
                <p className=" text-medium primary-color message">
                  {totalDays}
                </p>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {roles.includes("dashboard.admin") ? (
            <button
              className="text-bold text-medium primary-color container report-button"
              style={{
                opacity: !canGenerateReport ? 0.6 : 1,
              }}
              disabled={!canGenerateReport}
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  downloadReport({
                    idClient: idClient,
                    filter: currentFilteredRequest,
                  })
                )
                  .then(() => {
                    setReportGenerated(true);
                    setShowAlert(true);
                    setTimeout(() => {
                      setShowAlert(false);
                    }, 3000);
                  })
                  .catch((error) => {
                    setReportGenerated(false);

                    return error;
                  });
              }}
            >
              {loadingDownloadReport === "pending" ? (
                <ClipLoader color="white" size="25px" />
              ) : (
                <>
                  descargar reporte
                  <DownloadIcon2
                    style={{ marginLeft: "15px", width: "19px" }}
                  />
                </>
              )}
            </button>
          ) : (
            ""
          )}

          {loadingDownloadReport === "pending" ? (
            <p className="text-bold text-medium primary-color message">
              {loadingMessage[showWaitingMessage]}
            </p>
          ) : loadingDownloadReport === "resolved" && reportGenerated ? (
            <div></div>
          ) : (
            loadingDownloadReport === "rejected" && (
              <Alert
                style={{ margin: "10px", top: 0, bottom: 0 }}
                show={showAlert}
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                <p>
                  Ocurrió un error al generar el reporte, Intente de nuevo o
                  comuniquese con un administrador.
                </p>
              </Alert>
            )
          )}
        </>
      );
    }
    if (activeComponent === "Indicators") {
      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={false}
            calendarType="double"
            open={false}
            endDate={
              currentFilteredRequest.endDate
                ? new Date(currentFilteredRequest.endDate)
                : null
            }
            startDate={
              currentFilteredRequest.startDate
                ? new Date(currentFilteredRequest.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: null,
                    startDate: null,
                  })
                );
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: null,
                    startDate: null,
                  })
                );
              }
            }}
            items={[]}
          />
        </>
      );
    }
    if (activeComponent === "ConvertibilityFunnel") {
      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={false}
            calendarType="double"
            open={false}
            endDate={
              currentFilteredRequest.endDate
                ? new Date(currentFilteredRequest.endDate)
                : null
            }
            startDate={
              currentFilteredRequest.startDate
                ? new Date(currentFilteredRequest.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: null,
                    startDate: null,
                  })
                );
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: null,
                    startDate: null,
                  })
                );
              }
            }}
            items={[]}
          />
        </>
      );
    }

    if (activeComponent === "Advisors") {
      const statusOptions: { value: string; label: string }[] = [
        { label: "En proceso", value: "IN_PROGRESS" },
        { label: "En revisión", value: "AWAITING_MANUAL_INTERACTION" },
        { label: "En espera a usuario", value: "AWAITING_USER_INTERACTION" },
        { label: "Desistido", value: "ABANDONED" },
        { label: "Completado", value: "DONE" },
      ];

      const projectOptions: { value: string; label: string }[] = [];

      const { hyperFlows } = listFlowsOptions || {};
      if (hyperFlows) {
        for (const project in hyperFlows) {
          let label = hyperFlows[project];
          let value = project;
          projectOptions.push({ label, value });
        }
      }

      const projectDropdown = (
        <DropDown
          key={"Flows"}
          title="Flujo"
          defaultValue={flowSelect}
          options={projectOptions}
          onChange={(e) => {
            if (e) {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  flowId: e.value,
                })
              );

              setFlowSelect({ value: e.value, label: e.label });
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  flowId: null,
                })
              );
              setFlowSelect({ value: "", label: "Seleccione" });
            }
          }}
        />
      );

      const statusDropdown = (
        <DropDown
          key={"State"}
          title="Estado"
          options={statusOptions}
          defaultValue={statusSelect}
          onChange={(e) => {
            if (e) {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  flowState: e.value,
                })
              );

              setStatusSelect({ value: e.value, label: e.label });
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  flowState: null,
                })
              );

              setStatusSelect({ value: "", label: "Seleccione" });
            }
          }}
        />
      );

      const FilterOptionsAdvisors = (
        <DropDown
          key={"Asesor"}
          title="Asesor"
          defaultValue={advisorSelect}
          options={
            asesores?.map((asesor) => ({
              label: asesor.name,
              value: asesor.id,
            })) || []
          }
          onChange={(e) => {
            if (e) {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  asesorId: e.value,
                })
              );

              setAdvisorSelect({ value: e.value, label: e.label });

              dispatch(setNameAdvisor(e.label));
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  asesorId: null,
                })
              );
              setAdvisorSelect({ value: "", label: "Seleccione" });
            }
          }}
        />
      );

      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={false}
            calendarType="double"
            open={false}
            endDate={
              currentFilteredRequest.endDate
                ? new Date(currentFilteredRequest.endDate)
                : null
            }
            startDate={
              currentFilteredRequest.startDate
                ? new Date(currentFilteredRequest.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: null,
                    startDate: null,
                  })
                );
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: null,
                    startDate: null,
                  })
                );
              }
            }}
            items={[FilterOptionsAdvisors, projectDropdown, statusDropdown]}
          />
        </>
      );
    }
  };

  const getCategoryItems = () => {
    let categories: JSX.Element[] = [];
    categories.push(
      <Category
        key={0}
        name="Informes"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Dashboard"));
          dispatch(changeActiveComponent("Reports"));
          navigate("/services/dashboardClient/reports");
        }}
        selected={activeComponent === "Reports"}
      />
    );
    categories.push(
      <Category
        key={1}
        name="Indicadores"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Dashboard"));
          dispatch(changeActiveComponent("Indicators"));
          navigate("/services/dashboardClient/indicators");
        }}
        selected={activeComponent === "Indicators"}
      />
    );
    categories.push(
      <Category
        key={2}
        name="Embudo de convertibilidad"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Dashboard"));
          dispatch(changeActiveComponent("ConvertibilityFunnel"));
          navigate("/services/dashboardClient/convertibilityFunnel");
        }}
        selected={activeComponent === "ConvertibilityFunnel"}
      />
    );

    categories.push(
      <Category
        key={3}
        name="Asesores"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Dashboard"));
          dispatch(changeActiveComponent("Advisors"));
          navigate("/services/dashboardClient/advisors");
        }}
        selected={activeComponent === "Advisors"}
      />
    );

    return categories;
  };

  useEffect(() => {
    dispatch(changeCurrentProduct("Dashboard"));
    if (activeComponent === "none") {
      dispatch(changeActiveComponent("Indicators"));
    }

    if (loadingDownloadReport === "pending") {
      const timer = setInterval(() => {
        setShowWaitingMessage((prevMessage) =>
          prevMessage === loadingMessage.length - 1 ? 0 : prevMessage + 1
        );
      }, 16000);
      return () => clearTimeout(timer);
    }
  }, [activeComponent, loadingDownloadReport]);

  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />

      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={<CategoriesComponent categories={getCategoryItems()} />}
        />
        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
