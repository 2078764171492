import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { delay } from "../../../helpers/utils";
import { LoadingStatus } from "../../common/commonSlice";
import {
  getAlertsByItemIdAPI,
  UnifiedAlertsFiltered,
} from "../alerts/alertsAPI";
import {
  Attatchment,
  getImagesRegistrationAPI,
  getRegistrationByIdAPI,
  Pictures,
} from "../coreid/coreIdAPI";
import {
  getGeneratedDocumentsNewAPI,
  getSignatureByIdAPI,
} from "../firmanew/firmaNewAPI";
import {
  FilledForm,
  getFilledFormByIdAPI,
  getPdfFilledFormsAPI,
  getUserAttatchmentsAPI,
} from "../magicforms/magicFormsAPI";
import {
  getMiniDashboardDataAPI,
  changeFlowStatusAPI,
  HyperFlow,
  GetFilteredHyperFlowRequest,
  FlowFilteredOptions,
  getFilteredFlowAPI,
  getFilterOptionsFlowAPI,
  getFlowByIdAPI,
  GetStatsHyperFlowResponse,
  getRevisionFlowAPI,
  getRetryStep,
  getDataShowcaseByExecFlowIdAPI,
  DataShowcase,
  CustomStateResponse,
  getCustomStatesAPI,
  ResolveCustomStateRequest,
  resolveCustomStateAPI,
  ExecutedStep,
  ExecuteFlowResponse,
  executeFlowAPI,
  sendContinueEmailAPI,
  reExecuteStepAPI,
  getCheckListsAPI,
  SendFlowLinkRequest,
  sendEmailLinkAPI,
  GetManualInputDataResponse,
  getManualInputDataAPI,
  resolveManualInputAPI,
  getRpaStatusAPI,
  switchRpaStatusAPI,
  getFilteredFlowSentAPI,
  GetFilteredFlowSentRequest,
  GetFilteredFlowSentResponse,
  sendEmailLinkToUnregisteredAPI,
  generateSentLinkReportAPI,
  SupportFile,
  getSupportFilesAPI,
  ResolveManualInputRequest,
  getExecFlowAttachmentsAPI,
} from "./hyperFlowAPI";
import { getPagareByIdAPI, getPagareDocumentsAPI } from "../pagares/pagareAPI";
import { getResultsAPI, ResultData, ResultResponse } from "./externalInfo";

export type HyperflowActiveComponent = "HyperFlows" | "Analytics" | "CustomLists";

export interface HyperFlowState {
  loadingFilterOptions: LoadingStatus;
  loadingList: LoadingStatus;
  loadingHyperFlow: LoadingStatus;
  loadingRegistrationAlerts: LoadingStatus;
  loadingAttachments: LoadingStatus;
  loadingImages: LoadingStatus;
  loadingDashboardData: LoadingStatus;
  documentLoading: LoadingStatus;
  currentFilteredRequest: GetFilteredHyperFlowRequest;
  page: number;
  index: number;
  numPages: number;
  flowFiltered: HyperFlow[] | null;
  flowFilteredRevision: HyperFlow[] | null;
  filteredOptions: FlowFilteredOptions | null;
  currentFlow: HyperFlow | null;
  isFlowDetail: boolean;
  currentFlowStatus: string;
  miniDashboarData: GetStatsHyperFlowResponse;
  filledForm: FilledForm | null;
  userAttachments: Array<Attatchment>;
  userImages?: Pictures;
  currentRequestIdImagenes: string | undefined;
  activeComponent: HyperflowActiveComponent;
  dataShowcase: DataShowcase | null;
  loadingShowcase: LoadingStatus;
  customStates: CustomStateResponse | null;
  loadingResolveState: LoadingStatus;
  executeFlowResponse: ExecuteFlowResponse | null;
  hyperflowAlerts: UnifiedAlertsFiltered | null;
  loadingHyperflowAlerts: LoadingStatus;
  hyperflowError: string;
  loadingSendLink: LoadingStatus;
  loadingManualInputData: LoadingStatus;
  manualInputData: GetManualInputDataResponse;
  showRpaStatus: boolean;
  rpaStatus: "PENDING" | "ERROR" | "COMPLETE";
  rpaStatusLoading: LoadingStatus;
  sentLinkFlowLoading: LoadingStatus;
  filteredSentFlowReq: GetFilteredFlowSentRequest;
  filteredSentFlowRes: GetFilteredFlowSentResponse;
  downloadingSentLinkReport: LoadingStatus;
  downloadSupportFiles: LoadingStatus;
  currentSupportFiles: SupportFile[] | undefined;
  currentResultData: ResultResponse[] | undefined;
  loadingCurrentResultData: LoadingStatus;
}

const initialState: HyperFlowState = {
  loadingShowcase: "idle",
  loadingList: "idle",
  loadingFilterOptions: "idle",
  loadingHyperFlow: "idle",
  loadingDashboardData: "idle",
  documentLoading: "idle",
  loadingAttachments: "idle",
  loadingImages: "idle",
  loadingRegistrationAlerts: "idle",
  currentFilteredRequest: {
    searchQuery: null,
    endDate: null,
    startDate: null,
    status: null,
    hyperFlowId: null,
    page: 0,
    tagId: null,
    stepFilter: null,
  },
  flowFiltered: null,
  flowFilteredRevision: null,
  page: 0,
  index: 0,
  numPages: 0,
  filteredOptions: {
    hyperFlows: {},
    stepFilters: {},
  },

  miniDashboarData: {
    total: 0,
    rejected: 0,
    accepted: 0,
    pending: 0,
    new: 0,
  },
  currentFlow: null,
  isFlowDetail: false,
  currentFlowStatus: "IN_PROGRESS",
  filledForm: null,
  userAttachments: [],
  userImages: undefined,
  currentRequestIdImagenes: undefined,
  activeComponent: "HyperFlows",
  dataShowcase: null,
  customStates: null,
  loadingResolveState: "idle",
  executeFlowResponse: null,
  hyperflowAlerts: null,
  loadingHyperflowAlerts: "idle",
  hyperflowError: "",
  loadingSendLink: "idle",
  loadingManualInputData: "idle",
  manualInputData: {
    showChangelableDataWindow: false,
    items: [],
  },
  showRpaStatus: false,
  rpaStatus: "PENDING",
  rpaStatusLoading: "idle",
  sentLinkFlowLoading: "idle",
  filteredSentFlowReq: {
    page: 0,
    status: null,
  },
  filteredSentFlowRes: {
    numPages: 0,
    sentLinkFlows: [],
  },
  downloadingSentLinkReport: "idle",
  currentSupportFiles: undefined,
  downloadSupportFiles: "idle",
  currentResultData: undefined,
  loadingCurrentResultData: "idle",
};

let getDataEachStep = async (value: HyperFlow) => {
  let steps = value.executedSteps ? value.executedSteps : {};
  let stepsdata = Object.keys(steps);
  for (let i = 0; i < stepsdata.length; i++) {
    let element = stepsdata[i];
    let id = steps[element].entityId;
    if (steps[element].stepDescriptionId === "CHECK_LISTS") {
      id = "CHECK_LISTS";
    }
    if (id != "") {
      switch (steps[element].stepDescriptionId) {
        case "CORE_ID":
          steps[element].detailCore = value.registration;
          break;
        case "MAGIC_FORMS":
          let magic = await getFilledFormByIdAPI(id);
          let magic_pdf = await getPdfFilledFormsAPI(id);
          let filled = magic.filledForm;

          if (filled) {
            filled.document = magic_pdf.pdf?.pdfBase64;
          }
          if (
            filled &&
            filled?.electronicSignatureId &&
            filled?.electronicSignatureId !== ""
          ) {
            let sigid = filled.electronicSignatureId;
            let firma = await getSignatureByIdAPI(sigid);
            let firma_docs = await getGeneratedDocumentsNewAPI(sigid);
            let customId = "firmaMagic:" + filled.id;
            let firma_data = firma.signature;
            if (firma_data) {
              firma_data.documents = firma_docs.documents;
            }
            steps[customId] = {
              id: customId,
              status: "DONE",
              entityId: filled.electronicSignatureId,
              customName: "Firma del formulario: " + filled.id,
              stepDescriptionId: "ELECTRONIC_SIGNATURE",
              detailSig: firma_data,
              errorData: "",
              nextStepId: "",
              resultData: [],
              reExecutable: false,
            };

            if (firma_data && firma_data.hasPagare && firma_data.pagareRootId) {
              let pagareId = firma_data.pagareRootId;
              let pagare = await getPagareByIdAPI(pagareId);
              let pagareDocs = await getPagareDocumentsAPI(pagareId);
              let customIdPagare = "pagareMagic" + filled.id;
              let pagare_data = pagare.pagare;
              if (pagare_data) {
                pagare_data.documents = pagareDocs.documents;
              }
              steps[customIdPagare] = {
                id: customIdPagare,
                status: "DONE",
                entityId: pagareId,
                customName: `Pagaré del formulario: ${filled.id} asociado a la firma ${filled?.electronicSignatureId}`,
                stepDescriptionId: "PAGARE",
                detailPagare: pagare_data,
                errorData: "",
                nextStepId: "",
                resultData: [],
                reExecutable: false,
              };
            }
          }
          steps[element].detailMagic = filled;
          break;
        case "ELECTRONIC_SIGNATURE":
          let firma = await getSignatureByIdAPI(id);
          let firma_docs = await getGeneratedDocumentsNewAPI(id);

          let firma_data = firma.signature;
          if (firma_data) {
            firma_data.documents = firma_docs.documents;
          }

          if (firma_data && firma_data.hasPagare && firma_data.pagareRootId) {
            let pagareId = firma_data.pagareRootId;
            let pagare = await getPagareByIdAPI(pagareId);
            let pagareDocs = await getPagareDocumentsAPI(pagareId);
            let customIdPagare = "pagareFirma" + id;
            let pagare_data = pagare.pagare;
            if (pagare_data) {
              pagare_data.documents = pagareDocs.documents;
            }
            steps[customIdPagare] = {
              id: customIdPagare,
              status: "DONE",
              entityId: pagareId,
              customName: `Pagaré asociado a la firma ${id}`,
              stepDescriptionId: "PAGARE",
              detailPagare: pagare_data,
              errorData: "",
              nextStepId: "",
              resultData: [],
              reExecutable: false,
            };
          }

          steps[element].detailSig = firma_data;
          break;
        case "PAGARE":
          let firmaPagare = await getSignatureByIdAPI(id);
          let firmaPagareDocs = await getGeneratedDocumentsNewAPI(id);

          let firmaPagereData = firmaPagare.signature;
          if (firmaPagereData) {
            firmaPagereData.documents = firmaPagareDocs.documents;
          }

          if (
            firmaPagereData &&
            firmaPagereData.hasPagare &&
            firmaPagereData.pagareRootId
          ) {
            let pagareId = firmaPagereData.pagareRootId;
            let pagare = await getPagareByIdAPI(pagareId);
            let pagareDocs = await getPagareDocumentsAPI(pagareId);
            let customIdPagare = "pagareFirma" + id;
            let pagare_data = pagare.pagare;
            if (pagare_data) {
              pagare_data.documents = pagareDocs.documents;
            }
            steps[customIdPagare] = {
              id: customIdPagare,
              status: "DONE",
              entityId: pagareId,
              customName: `Pagaré asociado a la firma ${id}`,
              stepDescriptionId: "PAGARE",
              detailPagare: pagare_data,
              errorData: "",
              nextStepId: "",
              resultData: [],
              reExecutable: false,
            };
          }
          if (firmaPagereData?.documents?.document.documentName) {
            let customIdFirma = "firmaPagare:" + id;
            steps[customIdFirma] = {
              id: customIdFirma,
              status: "DONE",
              entityId: id,
              customName: "Firma del pagare: " + id,
              stepDescriptionId: "ELECTRONIC_SIGNATURE",
              detailSig: firmaPagereData,
              errorData: "",
              nextStepId: "",
              resultData: [],
              reExecutable: false,
            };
          }
          break;
        case "MULTI_SIGN_PAGARE":
          let firmaMultiSign = await getSignatureByIdAPI(id);
          let firmaMultiSignDocs = await getGeneratedDocumentsNewAPI(id);

          let firmaMultiSignData = firmaMultiSign.signature;
          if (firmaMultiSignData) {
            firmaMultiSignData.documents = firmaMultiSignDocs.documents;
          }

          if (
            firmaMultiSignData &&
            firmaMultiSignData.hasPagare &&
            firmaMultiSignData.pagareRootId
          ) {
            let pagareId = firmaMultiSignData.pagareRootId;
            let pagare = await getPagareByIdAPI(pagareId);
            let pagareDocs = await getPagareDocumentsAPI(pagareId);
            let customIdPagare = "pagareFirma" + id;
            let pagare_data = pagare.pagare;
            if (pagare_data) {
              pagare_data.documents = pagareDocs.documents;
            }
            steps[customIdPagare] = {
              id: customIdPagare,
              status: "DONE",
              entityId: pagareId,
              customName: `Pagaré asociado a la firma ${id}`,
              stepDescriptionId: "PAGARE",
              detailPagare: pagare_data,
              errorData: "",
              nextStepId: "",
              resultData: [],
              reExecutable: false,
            };
          }
          if (firmaMultiSignData?.documents?.document.documentName) {
            let customIdFirma = "firmaMultiSignPagare:" + id;
            steps[customIdFirma] = {
              id: customIdFirma,
              status: "DONE",
              entityId: id,
              customName: "Firma del multisignpagare: " + id,
              stepDescriptionId: "ELECTRONIC_SIGNATURE",
              detailSig: firmaMultiSignData,
              errorData: "",
              nextStepId: "",
              resultData: [],
              reExecutable: false,
            };
          }
          break;
        case "CHECK_LISTS":
          let result = await getCheckListsAPI(value.id, "CHECK_LISTS");
          if (result.listResult) {
            let customIdLists = "checkLists:" + value.id;
            steps[element] = {
              id: customIdLists,
              status: "DONE",
              entityId: "CHECK_LISTS",
              customName: "CheckLists: " + value.id,
              stepDescriptionId: "CHECK_LISTS",
              detailLists: result.listResult,
              errorData: "",
              nextStepId: "",
              resultData: [],
              reExecutable: false,
            };
          }
          break;
      }
    }
  }
  return steps;
};

export const getFilterOptions = createAsyncThunk(
  "hyperflow/filterOptions",
  async (_, { rejectWithValue }) => {
    const filterOptions = await getFilterOptionsFlowAPI();

    if (filterOptions.error && filterOptions.error !== "") {
      return rejectWithValue(filterOptions.error);
    } else {
      return filterOptions;
    }
  }
);

export const getFlowById = createAsyncThunk(
  "hyperFlow/getFlowById",
  async (flowId: string, { rejectWithValue }) => {
    let res = await getFlowByIdAPI(flowId);
    if (!res.flow || (res.error && res.error !== "")) {
      return rejectWithValue(res.error);
    } else {
      try {
        let registrationId = res.flow.userId;

        if (registrationId != "") {
          let registration = await getRegistrationByIdAPI(registrationId);
          res.flow.registration = registration.registration;
          res.flow.executedSteps = await getDataEachStep(res.flow);
        }
      } catch {}

      return res.flow;
    }
  }
);

export const getHyperflowAlerts = createAsyncThunk(
  "hyperflow/getHyperflowAlerts",
  async (id: string, { rejectWithValue }) => {
    let alerts = await getAlertsByItemIdAPI(id, "HYPERFLOW");
    if (alerts.alerts) {
      return alerts.alerts;
    } else {
      return rejectWithValue(alerts.error);
    }
  }
);

export const getAttatchmentByRegistrationId = createAsyncThunk(
  "hyperflow/getAttachments",
  async (regId: string) => {
    let attatchments = await getUserAttatchmentsAPI(regId);

    return attatchments;
  }
);

export const getAttachmentsByExecFlow = createAsyncThunk(
  "hyperflow/getAttachmentsByExecFlow",
  async (flowId: string) => {
    let attatchments = await getExecFlowAttachmentsAPI(flowId);
    return attatchments;
  }
)

export const getDataShowcaseById = createAsyncThunk(
  "hyperflow/getShowcase",
  async (flowId: string) => {
    let showcase = await getDataShowcaseByExecFlowIdAPI(flowId);
    return showcase;
  }
);

export const getRegistrationImagesByRegistartionId = createAsyncThunk(
  "hyperFlow/getImages",
  async (regId: string, { rejectWithValue }) => {
    let images = await getImagesRegistrationAPI(regId);
    if (images.pictures) {
      return images.pictures;
    } else {
      return rejectWithValue(images.error);
    }
  }
);

export const getFilteredFlows = createAsyncThunk(
  "hyperFlow/getFilteredFlows",
  async (params: GetFilteredHyperFlowRequest, { rejectWithValue }) => {
    try {
      let filteredRegistrations = await getFilteredFlowAPI(params);

      if (filteredRegistrations.flows) {
        for (var i = 0; i < filteredRegistrations.flows.flows.length; i++) {
          let registrationId = filteredRegistrations.flows.flows[i].userId;
          if (registrationId != "") {
            try {
              let registration = await getRegistrationByIdAPI(registrationId);

              filteredRegistrations.flows.flows[i].registration =
                registration.registration;
            } catch {}
          }
        }
        return filteredRegistrations;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getRevisionMode = createAsyncThunk(
  "hyperFlow/getRevisionMode",
  async (_, { rejectWithValue }) => {
    try {
      let filteredRegistrations = await getRevisionFlowAPI();

      if (filteredRegistrations.flows) {
        for (var i = 0; i < filteredRegistrations.flows.flows.length; i++) {
          let registrationId = filteredRegistrations.flows.flows[i].userId;
          if (registrationId != "") {
            let registration = await getRegistrationByIdAPI(registrationId);

            filteredRegistrations.flows.flows[i].registration =
              registration.registration;
          }
        }

        return filteredRegistrations;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const changeStatusById = createAsyncThunk(
  "hyperFlow/changeFlowStatusById",
  async (data: ResolveCustomStateRequest, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const flow = currentState.hyperFlow.currentFlow;
    if (flow) {
      const updatedFlow = await changeFlowStatusAPI(
        data.selectedState,
        flow.id,
        flow.currentStepId,
        data.comment
      );
      await delay(5000);
      if (updatedFlow.error && updatedFlow.error !== "") {
        return rejectWithValue(updatedFlow.error);
      } else {
        return updatedFlow.flow;
      }
    } else {
      return;
    }
  }
);

export const getCustomStates = createAsyncThunk(
  "hyperflow/getCustomState",
  async (id: string) => {
    let customStates = await getCustomStatesAPI(id);
    return customStates;
  }
);

export const resolveCustomState = createAsyncThunk(
  "hyperFlow/resolveCustomState",
  async (data: ResolveCustomStateRequest, { getState }) => {
    const currentState = getState() as RootState;
    const flow = currentState.hyperFlow.currentFlow;
    if (flow) {
      await resolveCustomStateAPI(flow.id, data);
      await delay(2000);
      return true;
    } else {
      return false;
    }
  }
);

export const retryStepManual = createAsyncThunk(
  "hyperFlow/retryStepManual",
  async (test: string, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const flow2 = currentState.hyperFlow.currentFlow;
    if (flow2) {
      const response = await getRetryStep(test);
      await delay(5000);
      if (response.error && response.error !== "") {
        return rejectWithValue(response.error);
      } else {
        return;
      }
    } else {
      return;
    }
  }
);

export const getFlowFilledFormPDF = createAsyncThunk(
  "hyperFlow/getFlowFormPDF",
  async (id: string, { rejectWithValue }) => {
    try {
      const filledFormPDF = await getPdfFilledFormsAPI(id);
      return filledFormPDF.pdf;
    } catch {
      rejectWithValue("error");
    }
  }
);
export const getHyperFlowStats = createAsyncThunk(
  "hyperFlow/getHyperFlowStats",
  async (_, { rejectWithValue }) => {
    try {
      const hyperFlowStats = await getMiniDashboardDataAPI();

      if (hyperFlowStats.error && hyperFlowStats.error !== "") {
        rejectWithValue("error");
      } else {
        return hyperFlowStats.miniDasboardData;
      }
    } catch {
      rejectWithValue("error");
    }
  }
);

export const executeFlow = createAsyncThunk(
  "hyperFlow/executeFlow",
  async (id: string, {}) => {
    const response = await executeFlowAPI(id);
    return response.result;
  }
);

export const sendContinueEmail = createAsyncThunk(
  "hyperFlow/sendContinueEmail",
  async (data: { execFlowId: string }, {}) => {
    const response = await sendContinueEmailAPI(data.execFlowId);

    return response.result;
  }
);

export const reExecuteStep = createAsyncThunk(
  "hyperFlow/reExecuteStep",
  async (data: { execFlowId: string; stepId: string }, {}) => {
    console.log("reExecuteStep");
    const response = await reExecuteStepAPI(data.execFlowId, data.stepId);
    delay(5000);
    return response.result;
  }
);

export const sendLinkByEmail = createAsyncThunk(
  "hyperFlow/sendLinkByEmail",
  async (data: SendFlowLinkRequest, {}) => {
    const response = await sendEmailLinkAPI(data);

    return response.result;
  }
);

export const sendLinkToUnregistered = createAsyncThunk(
  "hyperFlow/sendLinkToUnregistered",
  async (data: SendFlowLinkRequest, {}) => {
    const response = await sendEmailLinkToUnregisteredAPI(data);

    return response.result;
  }
);

export const generateSentLinkReport = createAsyncThunk(
  "hyperFlow/generateSentLinkReport",
  async () => {
    const response = await generateSentLinkReportAPI();

    return true;
  }
);
export const getSentFlowLink = createAsyncThunk(
  "hyperFlow/getSentFlowLink",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const req = currentState.hyperFlow.filteredSentFlowReq;
    const filteredSentFlow = await getFilteredFlowSentAPI(req);
    return filteredSentFlow.result;
  }
);
export const getManualInputData = createAsyncThunk(
  "hyperFlow/getManualInputData",
  async (_, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const flow = currentState.hyperFlow.currentFlow;
    if (flow) {
      try {
        const response = await getManualInputDataAPI(flow.id);
        return response.result;
      } catch {
        rejectWithValue("error");
      }
    }
  }
);

export const resolveManualInputData = createAsyncThunk(
  "hyperFlow/resolveManualInputData",
  async (_, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const state = currentState.hyperFlow;
    const flow = state.currentFlow;
    const data = state.manualInputData;
    const resData: { [key: string]: string } = {};
    data.items.forEach((item) => {
      resData[item.id] = item.value;
    });
    if (flow) {
      const response = await resolveManualInputAPI(flow.id, {
        step: flow.currentStepId,
        data: resData,
      });
      await delay(6000);
      return response.result;
    } else {
      rejectWithValue("error");
    }
  }
);

export const resolveManualInputDataV2 = createAsyncThunk(
  "hyperFlow/resolveManualInputDataV2",
  async (req: ResolveManualInputRequest, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const state = currentState.hyperFlow;
    const flow = state.currentFlow;
    const data = state.manualInputData;
    const resData: { [key: string]: string } = {};
    data.items.forEach((item) => {
      resData[item.id] = item.value;
    });
    if (flow) {
      const response = await resolveManualInputAPI(flow.id, req);
      await delay(6000);
      return response.result;
    } else {
      rejectWithValue("error");
    }
  }
);

export const getRpaStatus = createAsyncThunk(
  "hyperFlow/getRpaStatus",
  async (_, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const flow = currentState.hyperFlow.currentFlow;
    if (flow) {
      const response = await getRpaStatusAPI(flow.id);
      return response.result;
    } else {
      rejectWithValue("error");
    }
  }
);

export const switchRpaStatus = createAsyncThunk(
  "hyperFlow/switchRpaStatus",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const flow = currentState.hyperFlow.currentFlow;
    if (flow) {
      await switchRpaStatusAPI(flow.id);
    }
  }
);

export const getSupportFiles = createAsyncThunk(
  "hyperFlow/getSupportFiles",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const id = currentState.hyperFlow.currentFlow?.id;
    const response = await getSupportFilesAPI(id!);
    return response.result;
  }
);

export const getResultData = createAsyncThunk(
  "hyperFlow/getResultData",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const flow = currentState.hyperFlow.currentFlow;
    if (flow) {
      const response = await getResultsAPI(flow.id);
      return response;
    }
  }
);

export const hyperFlowSlice = createSlice({
  name: "hyperFlow",
  initialState,
  reducers: {
    changeCurrentFilters: (
      state,
      action: PayloadAction<GetFilteredHyperFlowRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload };
    },
    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, page: action.payload };
    },
    changePageSize: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, pageSize: action.payload };
    },
    changeIndex: (state, action: PayloadAction<number>) => {
      state.index = action.payload;
    },
    changeSentLinkFilters: (
      state,
      action: PayloadAction<GetFilteredFlowSentRequest>
    ) => {
      state.filteredSentFlowReq = { ...action.payload };
    },
    setSentLinkResponse: (
      state,
      action: PayloadAction<GetFilteredFlowSentResponse>
    ) => {
      state.filteredSentFlowRes = action.payload;
      state.sentLinkFlowLoading = "idle";
    },
    resetFlow: (state) => {
      state.currentFlow = null;
      state.hyperflowError = "";
      state.documentLoading = "idle";
      state.loadingAttachments = "idle";
      state.loadingHyperFlow = "idle";
      state.loadingRegistrationAlerts = "idle";
      state.loadingAttachments = "idle";
      state.loadingImages = "idle";
      state.documentLoading = "idle";
      state.userAttachments = [];
      state.userImages = undefined;
      state.loadingShowcase = "idle";
      state.dataShowcase = null;
      state.customStates = null;
      state.loadingHyperflowAlerts = "idle";
      state.hyperflowAlerts = null;
      state.loadingSendLink = "idle";
      state.loadingManualInputData = "idle";
      state.manualInputData = {
        showChangelableDataWindow: false,
        items: [],
      };
      state.showRpaStatus = false;
      state.rpaStatus = "PENDING";
      state.rpaStatusLoading = "idle";
      state.sentLinkFlowLoading = "idle";
      state.filteredSentFlowReq = {
        page: 0,
        status: null,
      };
      state.filteredSentFlowRes = {
        numPages: 0,
        sentLinkFlows: [],
      };
      state.currentResultData = undefined;
      state.loadingCurrentResultData = "idle";
    },
    setIsFlowDetail: (state, action: PayloadAction<boolean>) => {
      state.isFlowDetail = action.payload;
    },
    changeCurrentFlowStatus: (state, action: PayloadAction<string>) => {
      if (state.currentFlow) {
        state.currentFlowStatus = action.payload;
      }
    },
    changeHyperflowActiveComponent: (
      state,
      action: PayloadAction<HyperflowActiveComponent>
    ) => {
      state.activeComponent = action.payload;
    },
    emptyFlowExecution: (state) => {
      state.executeFlowResponse = null;
    },
    changeManualInputState: (
      state,
      action: PayloadAction<{ id: string; data: string }>
    ) => {
      const { id, data } = action.payload;
      const index = state.manualInputData.items.findIndex(
        (item) => item.id === id
      );
      if (index !== -1) {
        state.manualInputData.items[index].value = data;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredFlows.fulfilled, (state, action) => {
        let registrations = action.payload;

        if (registrations && registrations.flows) {
          state.flowFiltered = registrations.flows.flows;
          state.page = registrations.flows.page ? registrations.flows.page : 0;
          state.numPages = registrations.flows.numPages;
          state.loadingList = "resolved";
        } else {
          state.loadingList = "rejected";
        }
      })
      .addCase(getFilteredFlows.rejected, (state) => {
        state.loadingList = "rejected";
        state.page = 0;
        state.numPages = 0;
      })
      .addCase(getFilteredFlows.pending, (state) => {
        state.loadingList = "pending";
      })
      .addCase(getRevisionMode.fulfilled, (state, action) => {
        let registrations = action.payload;

        if (registrations && registrations.flows) {
          state.flowFilteredRevision = registrations.flows.flows;
          state.page = registrations.flows.page ? registrations.flows.page : 0;
          state.numPages = registrations.flows.numPages;
          state.index = 0;
          state.loadingHyperFlow = "resolved";
        } else {
          state.loadingHyperFlow = "rejected";
        }
      })
      .addCase(getRevisionMode.rejected, (state) => {
        state.loadingHyperFlow = "rejected";
        state.page = 0;
        state.numPages = 0;
      })
      .addCase(getRevisionMode.pending, (state) => {
        state.loadingHyperFlow = "pending";
      })

      .addCase(getFilterOptions.fulfilled, (state, action) => {
        let filterOptions = action.payload;
        if (filterOptions) {
          state.filteredOptions = filterOptions.filterOptions;
          state.loadingFilterOptions = "resolved";
        } else {
          state.loadingFilterOptions = "rejected";
        }
      })
      .addCase(getFilterOptions.pending, (state) => {
        state.loadingFilterOptions = "pending";
      })
      .addCase(getFilterOptions.rejected, (state) => {
        state.loadingFilterOptions = "rejected";
        state.filteredOptions = {
          hyperFlows: {},
          stepFilters: {},
        };
      })
      .addCase(getFlowById.fulfilled, (state, action) => {
        let flow = action.payload;
        if (flow) {
          state.currentFlow = flow;
          state.loadingHyperFlow = "resolved";
        } else {
          state.loadingHyperFlow = "rejected";
        }
      })
      .addCase(getFlowById.pending, (state) => {
        state.loadingHyperFlow = "pending";
      })
      .addCase(getFlowById.rejected, (state, action) => {
        state.loadingHyperFlow = "rejected";
        state.currentFlow = null;
        state.hyperflowError = action.payload as string;
      })
      .addCase(getHyperflowAlerts.fulfilled, (state, action) => {
        let alerts = action.payload;
        if (alerts) {
          state.hyperflowAlerts = alerts;
          state.loadingHyperflowAlerts = "resolved";
        } else {
          state.loadingHyperflowAlerts = "rejected";
        }
      })
      .addCase(getHyperflowAlerts.pending, (state) => {
        state.loadingHyperflowAlerts = "pending";
      })
      .addCase(getHyperflowAlerts.rejected, (state) => {
        state.loadingHyperflowAlerts = "rejected";
      })
      .addCase(getAttatchmentByRegistrationId.fulfilled, (state, action) => {
        let attatchments = action.payload;
        if (state.currentFlow && attatchments) {
          state.userAttachments = attatchments;
          state.loadingAttachments = "resolved";
        } else {
          state.userAttachments = [];
          state.loadingAttachments = "rejected";
        }
      })
      .addCase(getAttatchmentByRegistrationId.pending, (state) => {
        state.loadingAttachments = "pending";
      })
      .addCase(getAttatchmentByRegistrationId.rejected, (state) => {
        state.loadingAttachments = "rejected";
      })
      //Get ExecFlowAttachments
      .addCase(getAttachmentsByExecFlow.fulfilled, (state, action) => {
        let attatchments = action.payload;
        if (state.currentFlow && attatchments) {
          state.userAttachments = attatchments;
          state.loadingAttachments = "resolved";
        } else {
          state.userAttachments = [];
          state.loadingAttachments = "rejected";
        }
      })
      .addCase(getAttachmentsByExecFlow.pending, (state) => {
        state.loadingAttachments = "pending";
      })
      .addCase(getAttachmentsByExecFlow.rejected, (state) => {
        state.loadingAttachments = "rejected";
      })
      //Data Showcase
      .addCase(getDataShowcaseById.fulfilled, (state, action) => {
        let showcaseResponse = action.payload;
        if (showcaseResponse && showcaseResponse.dataShowcase) {
          state.dataShowcase = showcaseResponse.dataShowcase;
          state.loadingShowcase = "resolved";
        } else {
          state.loadingShowcase = "rejected";
        }
      })
      .addCase(getDataShowcaseById.pending, (state) => {
        state.loadingShowcase = "pending";
      })
      .addCase(getDataShowcaseById.rejected, (state) => {
        state.loadingShowcase = "rejected";
      })

      //Get Manual Input Data
      .addCase(getManualInputData.fulfilled, (state, action) => {
        let manualInputData = action.payload;
        if (manualInputData) {
          state.manualInputData = manualInputData;
          state.loadingManualInputData = "resolved";
        } else {
          state.loadingManualInputData = "rejected";
        }
      })
      .addCase(getManualInputData.pending, (state) => {
        state.loadingManualInputData = "pending";
      })
      .addCase(getManualInputData.rejected, (state) => {
        console.log("ERROR GETMANUALINPUT");
        state.loadingManualInputData = "rejected";
      })
      // Resolve Manual Input Data
      .addCase(resolveManualInputData.fulfilled, (state, action) => {
        state.loadingManualInputData = "resolved";
        state.loadingHyperFlow = "resolved";
        state.currentFlow = null;
        state.documentLoading = "idle";
        state.loadingAttachments = "idle";
        state.loadingHyperFlow = "idle";
        state.loadingRegistrationAlerts = "idle";
        state.loadingAttachments = "idle";
        state.loadingImages = "idle";
        state.documentLoading = "idle";
      })
      .addCase(resolveManualInputData.pending, (state) => {
        state.loadingManualInputData = "pending";
      })
      .addCase(resolveManualInputData.rejected, (state) => {
        state.loadingManualInputData = "rejected";
      })
      //Resolve manual input data v2
      .addCase(resolveManualInputDataV2.fulfilled, (state, action) => {
        state.loadingManualInputData = "resolved";
        state.loadingHyperFlow = "resolved";
        state.currentFlow = null;
        state.documentLoading = "idle";
        state.loadingAttachments = "idle";
        state.loadingHyperFlow = "idle";
        state.loadingRegistrationAlerts = "idle";
        state.loadingAttachments = "idle";
        state.loadingImages = "idle";
        state.documentLoading = "idle";
      })
      .addCase(resolveManualInputDataV2.pending, (state) => {
        state.loadingManualInputData = "pending";
      })
      .addCase(resolveManualInputDataV2.rejected, (state) => {
        state.loadingManualInputData = "rejected";
      })
      //getRpaStatus
      .addCase(getRpaStatus.fulfilled, (state, action) => {
        let res = action.payload;

        if (res) {
          console.log(res);
          state.rpaStatus = res.rpaStatus;
          state.rpaStatusLoading = "resolved";
          state.showRpaStatus = res.showRpaStatus;
        } else {
          state.rpaStatusLoading = "rejected";
        }
      })
      .addCase(getRpaStatus.pending, (state) => {
        state.rpaStatusLoading = "pending";
      })
      .addCase(getRpaStatus.rejected, (state) => {
        state.rpaStatusLoading = "rejected";
        state.showRpaStatus = false;
      })
      //switchRpaStatus
      .addCase(switchRpaStatus.fulfilled, (state) => {
        state.rpaStatusLoading = "idle";
      })
      .addCase(switchRpaStatus.pending, (state) => {
        state.rpaStatusLoading = "pending";
      })
      .addCase(switchRpaStatus.rejected, (state) => {
        state.rpaStatusLoading = "rejected";
      })

      // Get Registration Images
      .addCase(
        getRegistrationImagesByRegistartionId.fulfilled,
        (state, action) => {
          const { requestId } = action.meta;
          if (
            state.loadingImages === "pending" &&
            state.currentRequestIdImagenes === requestId
          ) {
            let images = action.payload;
            if (images) {
              state.userImages = images;
              state.loadingImages = "resolved";
            } else {
              state.loadingImages = "rejected";
            }
          }
        }
      )
      .addCase(
        getRegistrationImagesByRegistartionId.pending,
        (state, action) => {
          if (state.loadingImages === "idle") {
            state.loadingImages = "pending";
            state.currentRequestIdImagenes = action.meta.requestId;
          }
        }
      )
      .addCase(
        getRegistrationImagesByRegistartionId.rejected,
        (state, action) => {
          const { requestId } = action.meta;
          if (
            state.loadingImages === "pending" &&
            state.currentRequestIdImagenes === requestId
          ) {
            state.loadingImages = "rejected";
          }
        }
      )
      .addCase(changeStatusById.fulfilled, (state) => {
        state.loadingHyperFlow = "resolved";
        state.currentFlow = null;
        state.documentLoading = "idle";
        state.loadingAttachments = "idle";
        state.loadingHyperFlow = "idle";
        state.loadingRegistrationAlerts = "idle";
        state.loadingAttachments = "idle";
        state.loadingImages = "idle";
        state.documentLoading = "idle";
      })
      .addCase(changeStatusById.pending, (state) => {
        state.loadingHyperFlow = "pending";
      })
      .addCase(changeStatusById.rejected, (state) => {
        state.loadingHyperFlow = "rejected";
        state.currentFlow = null;
      })
      .addCase(getFlowFilledFormPDF.fulfilled, (state, action) => {
        let pdf = action.payload;
        let flow = state.currentFlow;
        if (flow && pdf) {
          // flow.filledForm.document = pdf.pdfBase64;
          state.documentLoading = "resolved";
          state.currentFlow = flow;
        } else {
          state.documentLoading = "rejected";
        }
      })
      .addCase(getFlowFilledFormPDF.pending, (state) => {
        state.documentLoading = "pending";
      })
      .addCase(getFlowFilledFormPDF.rejected, (state) => {
        state.documentLoading = "rejected";
      })
      .addCase(getHyperFlowStats.fulfilled, (state, action) => {
        let dashboardItems = action.payload;
        if (dashboardItems) {
          state.miniDashboarData = dashboardItems;
          state.loadingDashboardData = "resolved";
        } else {
          state.loadingDashboardData = "rejected";
        }
      })
      .addCase(getHyperFlowStats.rejected, (state) => {
        state.loadingDashboardData = "rejected";
      })
      .addCase(getHyperFlowStats.pending, (state) => {
        state.loadingDashboardData = "pending";
      })
      .addCase(retryStepManual.fulfilled, (state, action) => {
        state.loadingHyperFlow = "resolved";
        state.currentFlow = null;
        state.documentLoading = "idle";
        state.loadingAttachments = "idle";
        state.loadingHyperFlow = "idle";
        state.loadingRegistrationAlerts = "idle";
        state.loadingAttachments = "idle";
        state.loadingImages = "idle";
        state.documentLoading = "idle";
        console.log("RetryStep Fullfilled");
      })
      .addCase(retryStepManual.pending, (state) => {
        console.log("RetryStep Pending");
      })
      .addCase(retryStepManual.rejected, (state) => {
        console.log("RetryStep Rejected");
      })
      .addCase(getCustomStates.fulfilled, (state, action) => {
        if (action.payload.customState && action.payload.error === "") {
          state.customStates = action.payload.customState;
        }
      })
      .addCase(resolveCustomState.fulfilled, (state, action) => {
        if (action.payload) {
          state.currentFlow = null;
          state.loadingHyperFlow = "idle";
          state.customStates = null;
        }
        state.loadingResolveState = "resolved";
      })
      .addCase(resolveCustomState.pending, (state) => {
        state.loadingResolveState = "pending";
      })
      .addCase(reExecuteStep.pending, (state) => {
        state.loadingHyperFlow = "pending";
      })
      .addCase(reExecuteStep.rejected, (state) => {
        state.loadingHyperFlow = "rejected";
      })
      .addCase(reExecuteStep.fulfilled, (state) => {
        state.loadingHyperFlow = "resolved";
        state.currentFlow = null;
        state.documentLoading = "idle";
        state.loadingAttachments = "idle";
        state.loadingHyperFlow = "idle";
        state.loadingRegistrationAlerts = "idle";
        state.loadingAttachments = "idle";
        state.loadingImages = "idle";
        state.documentLoading = "idle";
      });

    // Execute flow
    builder.addCase(executeFlow.fulfilled, (state, action) => {
      if (action.payload) {
        state.executeFlowResponse = action.payload;
      }
    });

    // Send executed flow link throgh email
    builder
      .addCase(sendLinkByEmail.fulfilled, (state, action) => {
        state.loadingSendLink = "resolved";
      })
      .addCase(sendLinkByEmail.pending, (state) => {
        state.loadingSendLink = "pending";
      });
    // get sent flow link

    builder
      .addCase(getSentFlowLink.fulfilled, (state, action) => {
        if (action.payload) {
          state.filteredSentFlowRes = action.payload;
          state.sentLinkFlowLoading = "resolved";
        } else {
          state.sentLinkFlowLoading = "rejected";
        }
      })
      .addCase(getSentFlowLink.pending, (state) => {
        state.sentLinkFlowLoading = "pending";
      });
    // Resend executed flow link to unregistered
    builder
      .addCase(sendLinkToUnregistered.fulfilled, (state, action) => {
        state.loadingSendLink = "resolved";
      })
      .addCase(sendLinkToUnregistered.pending, (state) => {
        state.loadingSendLink = "pending";
      });

    // Downloading sent link report

    builder
      .addCase(generateSentLinkReport.fulfilled, (state) => {
        state.downloadingSentLinkReport = "resolved";
      })
      .addCase(generateSentLinkReport.pending, (state) => {
        state.downloadingSentLinkReport = "pending";
      });

    builder
      .addCase(getSupportFiles.fulfilled, (state, action) => {
        if (action.payload) {
          state.currentSupportFiles = action.payload;
        }
        state.downloadSupportFiles = "resolved";
      })
      .addCase(getSupportFiles.pending, (state) => {
        state.downloadSupportFiles = "pending";
      });

    // get result data external info
    builder
      .addCase(getResultData.fulfilled, (state, action) => {
        if (action.payload) {
          state.currentResultData = action.payload;
        }
        state.loadingCurrentResultData = "resolved";
      })
      .addCase(getResultData.pending, (state) => {
        state.loadingCurrentResultData = "pending";
      });
  },
});

export const {
  changeCurrentFilters,
  changePageState,
  resetFlow,
  setIsFlowDetail,
  changeCurrentFlowStatus,
  changeIndex,
  changeHyperflowActiveComponent,
  emptyFlowExecution,
  changeManualInputState,
  changeSentLinkFilters,
  setSentLinkResponse,
  changePageSize,
} = hyperFlowSlice.actions;

export const selectNumPagesHyperFlow = (state: RootState) =>
  state.hyperFlow.numPages;
export const selectPageSizeHyperFlow = (state: RootState) =>
  state.hyperFlow.currentFilteredRequest.pageSize;
export const selectIndex = (state: RootState) => state.hyperFlow.index;
export const selectFlowFiltered = (state: RootState) =>
  state.hyperFlow.flowFiltered;
export const selectFlowFilteredRevision = (state: RootState) =>
  state.hyperFlow.flowFilteredRevision;
export const selectFilteredOptions = (state: RootState) =>
  state.hyperFlow.filteredOptions;
export const selectCurrentFlow = (state: RootState) =>
  state.hyperFlow.currentFlow;
export const selectCurrentFilterRequest = (state: RootState) =>
  state.hyperFlow.currentFilteredRequest;
export const selectDocumentLoadingFlow = (state: RootState) =>
  state.hyperFlow.documentLoading;
export const selectIsFlowDetail = (state: RootState) =>
  state.hyperFlow.isFlowDetail;
export const selectCurrentFlowStatus = (state: RootState) =>
  state.hyperFlow.currentFlowStatus;
export const selectStatsFlow = (state: RootState) =>
  state.hyperFlow.miniDashboarData;
export const selectCurrentPageHyperFlow = (state: RootState) =>
  state.hyperFlow.currentFilteredRequest.page;
export const selectLoadingList = (state: RootState) =>
  state.hyperFlow.loadingList;
export const selectLoadingHyperflow = (state: RootState) =>
  state.hyperFlow.loadingHyperFlow;
export const selectLoadingAlerts = (state: RootState) =>
  state.hyperFlow.loadingRegistrationAlerts;
export const selectLoadingAttachments = (state: RootState) =>
  state.hyperFlow.loadingAttachments;
export const selectLoadingImages = (state: RootState) =>
  state.hyperFlow.loadingImages;
export const selectUserImages = (state: RootState) =>
  state.hyperFlow.userImages;
export const selectLoadingDashboard = (state: RootState) =>
  state.hyperFlow.loadingDashboardData;
export const selectHyperAttachments = (state: RootState) =>
  state.hyperFlow.userAttachments;
export const selectHyperFlowActiveComponent = (state: RootState) =>
  state.hyperFlow.activeComponent;
export const selectLoadingShowcase = (state: RootState) =>
  state.hyperFlow.loadingShowcase;
export const selectShowcase = (state: RootState) =>
  state.hyperFlow.dataShowcase;
export const selectCostumStates = (state: RootState) =>
  state.hyperFlow.customStates;
export const selectLoadingResolveState = (state: RootState) =>
  state.hyperFlow.loadingResolveState;
export const selectExecuteFlowResponse = (state: RootState) =>
  state.hyperFlow.executeFlowResponse;
export const selectLoadingHyperflowAlerts = (state: RootState) =>
  state.hyperFlow.loadingHyperflowAlerts;
export const selectHyperflowAlerts = (state: RootState) =>
  state.hyperFlow.hyperflowAlerts;
export const selectHyperflowError = (state: RootState) =>
  state.hyperFlow.hyperflowError;
export const selectLoadingSendLink = (state: RootState) =>
  state.hyperFlow.loadingSendLink;

export const selectManualInputData = (state: RootState) =>
  state.hyperFlow.manualInputData;
export const selectLoadingManualInputData = (state: RootState) =>
  state.hyperFlow.loadingManualInputData;

export const selectShowRpaStatus = (state: RootState) =>
  state.hyperFlow.showRpaStatus;
export const selectRpaStatus = (state: RootState) => state.hyperFlow.rpaStatus;
export const selectRpaStatusLoading = (state: RootState) =>
  state.hyperFlow.rpaStatusLoading;
export const selectSentFlowLink = (state: RootState) =>
  state.hyperFlow.filteredSentFlowRes;
export const selectSentFlowLinkFilters = (state: RootState) =>
  state.hyperFlow.filteredSentFlowReq;
export const selectSentFlowLinkLoading = (state: RootState) =>
  state.hyperFlow.sentLinkFlowLoading;
export const selectDownloadingSentLinkReport = (state: RootState) =>
  state.hyperFlow.downloadingSentLinkReport;
export const selectSupportFiles = (state: RootState) =>
  state.hyperFlow.currentSupportFiles;
export const selectDownloadSupportFilesLoading = (state: RootState) =>
  state.hyperFlow.downloadSupportFiles;

export const selectLoadingDataResult = (state: RootState) =>
  state.hyperFlow.loadingCurrentResultData;
export const selectCurrentDataResult = (state: RootState) =>
  state.hyperFlow.currentResultData;

export default hyperFlowSlice.reducer;
