import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct, selectRoles } from "../../common/commonSlice";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";

import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import {
  changeCurrentFilters,
  changeCurrentFlowStatus,
  changeHyperflowActiveComponent,
  getFilterOptions,
  selectCurrentFilterRequest,
  selectFilteredOptions,
  selectHyperFlowActiveComponent,
} from "./hyperFlowSlice";

import { DropDown } from "../../common/components/Btns/DropDown";
import {
  changeHyperflowFilters,
  resetStateAnalytics,
  selectHyperflowRequest,
} from "../analytics/analyticsSlice";
import { selectCurrentTagsByService } from "../tags/tagSlice";

export const HyperFlow = () => {
  let filterOptions = useAppSelector(selectFilteredOptions);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let hyperflowActiveComponent = useAppSelector(selectHyperFlowActiveComponent);
  let analyticsRequest = useAppSelector(selectHyperflowRequest);
  let roles = useAppSelector(selectRoles);
  let [search, setSearch] = useState(false);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let currentServiceTags = useAppSelector(selectCurrentTagsByService);

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    let tagsDropdown = <></>;
    if (roles.includes("tags.view")) {
      let tagsOptions: { value: string; label: string }[] = [];
      let tags = currentServiceTags;
      if (tags) {
        for (const tag of tags) {
          let label = tag.tag;
          let value = tag.id;
          if (tag.associatedEntities.length > 0) {
            tagsOptions.push({ label: label!, value });
          }
        }
      }
      tagsDropdown = (
        <DropDown
          key={"Tags"}
          title="Etiqueta"
          options={tagsOptions}
          onChange={(e) => {
            if (e) {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  tagId: e?.value!,
                })
              );
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  tagId: null,
                })
              );
            }
          }}
        />
      );
    }
    let projectOptions: { value: string; label: string }[] = [];
    let statusOptions: { value: string; label: string }[] = [
      { label: "En proceso", value: "IN_PROGRESS" },
      { label: "En revisión", value: "AWAITING_MANUAL_INTERACTION" },
      { label: "En espera a usuario", value: "AWAITING_USER_INTERACTION" },
      { label: "Desistido", value: "ABANDONED" },
      { label: "Completado", value: "DONE" },
    ];
    let projects = filterOptions?.hyperFlows;
    if (projects) {
      for (const project in projects) {
        let label = projects[project];
        let value = project;
        projectOptions.push({ label, value });
      }
    }

    let stepOptions: { value: string; label: string }[] = [];
    let steps = filterOptions?.stepFilters;
    if (steps) {
      for (const step in steps) {
        let label = steps[step];
        let value = step;
        stepOptions.push({ label, value });
      }
    }

    let stepDropdown = (
      <DropDown
        key={"steps"}
        title="Paso actual"
        options={stepOptions}
        onChange={(e) => {
          if (hyperflowActiveComponent === "Analytics") {
            dispatch(
              changeHyperflowFilters({
                ...analyticsRequest,
                stepFilter: e?.value!,
              })
            );
          } else if (hyperflowActiveComponent === "HyperFlows") {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                stepFilter: e?.value!,
              })
            );
          }
        }}
      />
    )

    let projectDropdown = (
      <DropDown
        key={"Projects"}
        title="Proyecto"
        options={projectOptions}
        onChange={(e) => {
          if (hyperflowActiveComponent === "Analytics") {
            dispatch(
              changeHyperflowFilters({
                ...analyticsRequest,
                hyperFlowId: e?.value!,
              })
            );
          } else if (hyperflowActiveComponent === "HyperFlows") {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                hyperFlowId: e?.value!,
              })
            );
          }
        }}
      />
    );
    let statusDropdown = (
      <DropDown
        key={"Status"}
        title="Estado"
        options={statusOptions}
        onChange={(e) => {
          if (hyperflowActiveComponent === "Analytics") {
            dispatch(
              changeHyperflowFilters({
                ...analyticsRequest,
                status: e?.value!,
              })
            );
          } else if (hyperflowActiveComponent === "HyperFlows") {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                status: e?.value!,
              })
            );
          }
        }}
      />
    );
    let searchInput = (
      <SearchInputFilter
        value={currentFilteredRequest.searchQuery}
        key={"SearchHyperFlow"}
        onChange={(e) => {
          if (!e.target.value || e.target.value === "") {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: null,
              })
            );
          } else {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: e.target.value,
              })
            );
          }
        }}
        style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
        placeHolder="cedula"
      />
    );
    if (hyperflowActiveComponent === "HyperFlows") {
      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={true}
            endDate={
              currentFilteredRequest.endDate
                ? new Date(currentFilteredRequest.endDate)
                : null
            }
            startDate={
              currentFilteredRequest.startDate
                ? new Date(currentFilteredRequest.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                if (date) {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      endDate: date.toISOString(),
                    })
                  );
                }
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                if (date) {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      startDate: date.toISOString(),
                    })
                  );
                }
              }
            }}
            resetFunction={() => {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  endDate: null,
                  startDate: null,
                })
              );
            }}
            items={[
              statusDropdown,
              projectDropdown,
              tagsDropdown,
              stepDropdown,
              <div
                key={0}
                className="text-normal text-small primary-color"
                style={{
                  alignSelf: "start",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                Por identificación
              </div>,
              searchInput,
            ]}
          />
        </>
      );
    } else if (hyperflowActiveComponent === "Analytics") {
      return (
        <FilterComponent
          key={0}
          hasCalendar={false}
          calendarType="double"
          endDate={
            analyticsRequest.endDate ? new Date(analyticsRequest.endDate) : null
          }
          startDate={
            analyticsRequest.startDate
              ? new Date(analyticsRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeHyperflowFilters({
                    ...analyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
                dispatch(
                  changeHyperflowFilters({
                    ...analyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeHyperflowFilters({
                    ...analyticsRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          items={[statusDropdown, projectDropdown]}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const getCategoryItems = (): JSX.Element[] => {
    let items: JSX.Element[] = [];
    items.push(
      <Category
        key={0}
        name="HyperFlows"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("HyperFlow"));
          dispatch(changeCurrentFlowStatus("HyperFlows"));
          navigate("/services/hyperFlow/flows");
        }}
        selected={hyperflowActiveComponent === "HyperFlows"}
      />
    );
    if (
      roles.includes("analytics.admin") ||
      roles.includes("analytics.hyperflow.view")
    ) {
      items.push(
        <Category
          key={1}
          name="Analitica"
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("HyperFlow"));
            dispatch(changeHyperflowActiveComponent("Analytics"));
            dispatch(resetStateAnalytics());
            navigate("/services/hyperFlow/analytics");
          }}
          selected={hyperflowActiveComponent === "Analytics"}
        />
      );
    }
    if (
      roles.includes("hyperflow.admin")
    ) {
      items.push(
        <Category
          key={1}
          name="Listas Personalizadas"
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("HyperFlow"));
            dispatch(changeHyperflowActiveComponent("HyperFlows"));
            navigate("/services/hyperFlow/customLists");
          }}
          selected={hyperflowActiveComponent === "HyperFlows"}
        />
      );
    }
    return items;
  };

  useEffect(() => {
    if (!search) {
      dispatch(getFilterOptions());
      setSearch(true);
    }
  }, [filterOptions, setSearch, hyperflowActiveComponent]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={<CategoriesComponent categories={getCategoryItems()} />}
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "space-between" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
